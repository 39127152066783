html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}

html {
  --scrollbarBG: #0D0D0D;
  --thumbBG: #BBEC6C;
}
body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

main {
  display: block;
  background-image: url('./img/bg1.svg'), url('./img/bg2.svg'), url('./img/sphere1.png'), url('./img/sphere2.png');
  background-position: 14% top, 86% bottom, left bottom, right bottom;
  background-repeat: no-repeat, no-repeat;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0; 
  overflow: visible; 
}
pre {
  font-family: monospace, monospace; 
  font-size: 1em; 
}
a {
  background-color: transparent;
  text-decoration:none;
}
abbr[title] {
  border-bottom: none; 
  text-decoration: underline; 
  text-decoration: underline dotted; 
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace; 
  font-size: 1em; 
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; 
  font-size: 100%; 
  line-height: 1.15; 
  margin: 0; 
}
button,
input { 
  overflow: visible;
}
button,
select { 
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box; 
  color: inherit; 
  display: table; 
  max-width: 100%; 
  padding: 0; 
  white-space: normal; 
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; 
  padding: 0; 
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield; 
  outline-offset: -2px; 
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button; 
  font: inherit; 
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}




@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProLight/GothamProLight.eot");
  src: url("fonts/GothamProLight/GothamProLight.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProLight/GothamProLight.woff") format("woff"),
  url("fonts/GothamProLight/GothamProLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProRegular/GothamProRegular.eot");
  src: url("fonts/GothamProRegular/GothamProRegular.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProRegular/GothamProRegular.woff") format("woff"),
  url("fonts/GothamProRegular/GothamProRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProMedium/GothamProMedium.eot");
  src: url("fonts/GothamProMedium/GothamProMedium.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProMedium/GothamProMedium.woff") format("woff"),
  url("fonts/GothamProMedium/GothamProMedium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProBold/GothamProBold.eot");
  src: url("fonts/GothamProBold/GothamProBold.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProBold/GothamProBold.woff") format("woff"),
  url("fonts/GothamProBold/GothamProBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProBlack/GothamProBlack.eot");
  src: url("fonts/GothamProBlack/GothamProBlack.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProBlack/GothamProBlack.woff") format("woff"),
  url("fonts/GothamProBlack/GothamProBlack.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProLightItalic/GothamProLightItalic.eot");
  src: url("fonts/GothamProLightItalic/GothamProLightItalic.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProLightItalic/GothamProLightItalic.woff") format("woff"),
  url("fonts/GothamProLightItalic/GothamProLightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProItalic/GothamProItalic.eot");
  src: url("fonts/GothamProItalic/GothamProItalic.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProItalic/GothamProItalic.woff") format("woff"),
  url("fonts/GothamProItalic/GothamProItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProMediumItalic/GothamProMediumItalic.eot");
  src: url("fonts/GothamProMediumItalic/GothamProMediumItalic.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProMediumItalic/GothamProMediumItalic.woff") format("woff"),
  url("fonts/GothamProMediumItalic/GothamProMediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProBoldItalic/GothamProBoldItalic.eot");
  src: url("fonts/GothamProBoldItalic/GothamProBoldItalic.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProBoldItalic/GothamProBoldItalic.woff") format("woff"),
  url("fonts/GothamProBoldItalic/GothamProBoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProBlackItalic/GothamProBlackItalic.eot");
  src: url("fonts/GothamProBlackItalic/GothamProBlackItalic.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProBlackItalic/GothamProBlackItalic.woff") format("woff"),
  url("fonts/GothamProBlackItalic/GothamProBlackItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 900;
}




* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  background: #0D0A13;
  font-family: "GothamPro";
}
.header-wrapper {
  position: fixed;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  left:50%;
  transform:translateX(-50%); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  background: #0D0A13;
}
.header {
  width: 1468px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  margin-left: 15px;
}
.header__logo {
  margin-right: 64px;
}
.header__left {
  display: flex;
  /* justify-content: flex-start; */
}
.header__right {
  display: flex;
}
.header__claim {
  margin-right: 8px;
  cursor: pointer;
}
.button__size {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-height: 53px;
  border-radius: 12px;
  padding: 16px 32px;
  font-weight: 700;
  color: var(--white, #FFF);
  font-size: 18px;
  font-style: normal;
  line-height: 16.667px; /* 92.593% */
  text-transform: capitalize;
}
.button__transparent {
  background: rgba(255, 255, 255, 0.10);
}
.button__transparent:hover {
  opacity: 0.8;
}
.button__style {
  background: #BBEC6C;
  color: #0D0A13;
}
.button__style:hover {
  opacity: 0.8;
}
textarea:focus, input:focus{
  outline: none;
}

.toggle-pill-dark input[type="checkbox"] {
  display: none;
}
.toggle-pill-dark input[type="checkbox"] + label {
  display: block;
  position: relative;
  width: 3em;
  height: 1.6em;
  border-radius: 1em;
  background: var(--342, linear-gradient(92deg, #1A251F 5.53%, #1B1227 97.6%));;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-transition: background 0.1s ease-in-out;
  transition: background 0.1s ease-in-out;
}
.toggle-pill-dark input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 1.2em;
  height: 1.2em;
  border-radius: 1em;
  background: var(--123123, radial-gradient(57.74% 50% at 50% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(40, 255, 87, 0.30) 100%));;
  position: absolute;
  left: 0.2em;
  top: 0.2em;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.toggle-pill-dark input[type="checkbox"]:checked + label:before {
  background: var(--2, linear-gradient(133deg, #B0FF09 24.47%, #05B8F1 109.92%));
  /* box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2); */
  left: 1.6em;
  -webkit-transform: rotate(295deg);
  transform: rotate(295deg);
}
.switcher {
  display: flex;
  align-items: center;
}
.switcher__title {
  color: var(--2, #FFF);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
  text-transform: uppercase;
  margin-left: 8px;
}
.bg-wrapper {
  display: block;
  max-width: 1498px;
  margin: 0 auto;
  padding-top: 220px;
  padding-right: 15px;
  padding-left: 15px;
}

.base {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1300px) {
  .base {
    flex-direction: column;
    align-items: center;
  }
  .info {
    margin-bottom: 18px;
  }
}
.info {
  margin-left: 119px;
  width: 360px;
}
@media (max-width: 1450px) {
  .info {
    margin-left: 0px;
  }
}
.info__girl {
  height: 170px;
  width: 60%;
  position: relative;
  border-radius: 12px 12px 0 0;
  background: #19161F;
  margin: 0 auto;
}
.info__pic {
  position: absolute;
  left: 50%;
  transform:translateX(-50%);
  bottom: -7px;
}
.info__max {
  padding: 32px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: #312F36;
}
.info__maxtitle {
  margin-bottom: 24px;
  color: var(--2, var(--white, #FFF));
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
  text-transform: uppercase;
}
.info__maxinfo {
  color: #BBEC6C;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 36px */
  text-transform: uppercase;
}
.info__text {
  height: 53px;
  padding: 4px 32px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: #312F36;
  color: var(--2, var(--white, #FFF));
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
  text-transform: uppercase;
}
@-webkit-keyframes pulse {
	0% { background-color: #0D0A13; }
	100% { background-color: #312F36; }
}
@keyframes pulse {
	0% { background-color: #0D0A13; }
	100% { background-color: #312F36; }
}
.info__pulse {
  -webkit-animation: pulse 400ms infinite alternate;
	        animation: pulse 400ms infinite alternate;
}
.chance__text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  color: var(--2, var(--white, #FFF));
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
  text-transform: uppercase;
}
.chance__amount {
  font-size: 54px;
  font-style: italic;
  font-weight: 600;
  line-height: 100%; /* 64px */
  text-transform: uppercase;

  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.chance__amount_left {
  color: #BBEC6C;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
.chance__amount_right {
  background: var(--1, linear-gradient(93deg, #AA1327 13.59%, #FF465E 87.82%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.chance__amountpercent {
  color: var(--2, var(--white, #FFF));
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 64px */
  text-transform: uppercase;
}
.chance__percent {
  color: rgba(255, 255, 255, 0.30);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 36px */
  text-transform: uppercase;
  margin-bottom: 24px;

  display: flex;
  justify-content: space-between;
}

.line {
  display: flex;
  margin-bottom: 8px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.10);
}
.line__left {
  background: var(--12421, linear-gradient(133deg, #B0FF09 24.47%, #05B8F1 109.92%));
  border-radius: 10px 0 0 10px;
  height: 12px;
}
.line__right {
  background: var(--1, linear-gradient(93deg, #AA1327 13.59%, #FF465E 87.82%));
  border-radius: 0 10px 10px 0;
  height: 12px;
}
.line__middle {
  background: #BBEC6C;
  height: 12px;
  border-radius: 10px;
}
.line__left_dark {
  height: 12px;
}
.line__right_dark {
  height: 12px;
}
.range {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}
.range__item {
  border-radius: 8px;
  background: #191919;
  padding: 8px;
  border: 1px solid #65DF6F;

  background: #191919;

  color: var(--white, #FFF);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  text-transform: capitalize;
}
.range__item_segment {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  text-transform: capitalize;
  border-radius: 8px;
  background: #191919;
  padding: 8px;
  border: 1px solid #fff;
}
.decision {
  display: grid;

  gap: 24px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 58px;
  margin-bottom: 48px;
}
.decision__play {
  border-radius: 12px;
  width: 100%;
  height: 58px;
  color: #FFF;
  border: 0;
  font-size: 24px;
  font-style: normal;
  margin-bottom: 48px;
  cursor: pointer;
  background: #BBEC6C;
  color: #0D0A13;
  text-align: center;
  font-weight: 700;
  line-height: 24px; /* 100% */
  text-transform: capitalize;
}
.decision__play:hover {
  opacity: 0.8;
}
.decision__left {
  border-radius: 12px;
  background: linear-gradient(77deg, #B0FF09 15.4%, #05B8F1 118.61%);
  border: 0;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
  text-transform: uppercase;
  cursor: pointer;
}
.decision__left:hover {
  background: linear-gradient(252deg, #B0FF09 10.61%, #05B8F1 109%);
}
.decision__right {
  border-radius: 12px;
  background: linear-gradient(93deg, #AA1327 13.59%, #FF465E 87.82%);
  border: 0;
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
  text-transform: uppercase;
  cursor: pointer;
}
.decision__right:hover {
  background: linear-gradient(273deg, #AA1327 2.55%, #FF465E 96.84%);
}
.game {
  max-width: 892px;
  width: 100%;
}

.segment {
  max-width: 892px;
  display: grid;
  gap: 24px;
  grid-template-columns: 0.47fr 0.47fr 1.01fr;
  grid-template-rows: auto;
  grid-template-areas: "from to amount";
}
.segment__from {
  grid-area: from;
}
.segment__to {
  grid-area: to;
}
.segment__amount {
  grid-area: amount;
}
.segment__title {
  font-size: 18px;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: var(--2, var(--white, #FFF));
  font-weight: 700;
  line-height: 100%; /* 18px */
}
.segment__title_span {
  color: var(--white, #BBEC6C);
}

.segment__input {
  border-radius: 8px;
  padding: 16px 16px 16px 24px;
  width: 100%;
  color: var(--white-op-80, rgba(255, 255, 255, 0.80));
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: #19161F;
}
.segment__bid {
  border-radius: 8px;
  display: flex;
  padding: 11px 16px 10px 24px;
  width: 100%;

  color: var(--white-op-80, rgba(255, 255, 255, 0.80));
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */

  display: flex;
  justify-content: space-between;

  border: 1px solid rgba(255, 255, 255, 0.20);

  background: #19161F;
}
.bid__switch {
  display: flex;
}
.bid__off {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.30);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  border-radius: 4px;
  background: #25222A;
  border: 0;
  padding: 6px 10px;
  cursor: pointer;
}
.bid__on {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  border-radius: 4px;
  background: var(--white, #FFF);
  border: 0;
  padding: 6px 10px;
  cursor: pointer;
}
.bid__input {
  background: transparent;
  border: 0;
  width: 40%;
  color: var(--2, var(--white, #FFF));
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  text-transform: uppercase;
}
.segment__buttons {
  margin-top: 8px;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.segment__setter {
  border-radius: 8px;
  border: 0;
  padding: 12px 24px;
  color: #FFF;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px; 
  cursor: pointer;
  background: #25222A;
  color: var(--white, #FFF);
  font-style: normal;
  text-transform: capitalize;
}
.split {
    max-width: 892px;
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "point amount";
}
.split__point {
  grid-area: point;
}
.table-wrapper {
  margin-top: 112px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media (max-width: 1529px) {
  .table-wrapper {
    overflow-x: scroll;
  }
}
.table {
  padding: 16px 24px;
	width: 1468px;
  height: 716px;
  margin: 0 auto;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: #19161F;
}
.table__top {
  display: grid;
  grid-template-columns: 1fr 1.218fr 1fr 1fr 0.628fr;
}
.table__title {
  padding: 12px 16px;
  color: var(--white-op-40, rgba(255, 255, 255, 0.40));
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.table__item {
  display: grid;
  grid-template-columns: 1fr 1.218fr 1fr 1fr 0.628fr;
  height: 64px;
  border-radius: 8px;
}
.table__cell {
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
}
.table__icon {
  margin-right: 12px;
}
.table__copy {
  cursor: pointer;
}
.table__text {
  color: var(--w, #FFF);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
}
.table__address {
  text-decoration-line: underline;
}
.table-wrapper::-webkit-scrollbar {
  height: 18px;
}
.table-wrapper::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.table-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 18px;
  border: 3px solid var(--scrollbarBG);
}
.tabs {
	width: 1468px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.tabs__tab {
	width: 361px;
  border-radius: 8px;
  padding: 16px 24px;
}
.tabs__active {
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: rgba(255, 255, 255, 0.10);
}
.tabs__white {
  line-height: 100%; /* 20px */
  text-transform: uppercase;
  color: var(--2, var(--white, #FFF));
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.tabs__span {
  color: var(--white, #BBEC6C);
}
.tabs__brown {
  color: rgba(255, 255, 255, 0.30);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
  text-transform: uppercase;
}
.tabs__left {
  display: flex;
  align-items: center;
}
.tabs__soon {
  padding: 7px 10px;
  border-radius: 4px;
  background: #25222A;
}
.tabs__soontext {
  font-weight: 600;
  text-transform: uppercase;
  color: #BBEC6C;
  font-size: 14px;
  font-style: normal;
  line-height: 24px; /* 171.429% */
}
.tabs__default {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #19161F;
}
.tabs__icon {
  margin-right: 8px;
}
.pagination-wrapper {
	width: 1468px;
  margin: 0 auto;
}
.pagination {
  margin-top: 16px;
  border-radius: 8px;
  background: #19161F;
  display: flex;
  width: max-content;
}
.pagination__cell {
  border: 0;
  background: #19161F;
  padding: 13px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination__cell_active {
  background: rgba(255, 255, 255, 0.08);
}
.pagination__cell_hover:hover {
  background: rgba(255, 255, 255, 0.08);
  cursor: pointer;
}
.pagination__text {
  text-align: center;
  min-width: 35px;
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
  color: var(--white, #FFF);
  font-weight: 700;
}
.pagination__text_active {
  color: #BBEC6C;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
}
.footer-wrapper {
  margin-top: 87px;
  height: 188px;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.20);
  background: #0D0A13;
  backdrop-filter: blur(12px);
}
.footer {
  max-width: 1468px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.footer__text {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-transform: uppercase;
}
@media (max-width: 1036px) {
  .footer-wrapper {
    height: 230px;
  }
  .footer__text {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 809px) {
  .chance__amount_left {
    font-size: 32px;
  }
  .chance__amountpercent {
    font-size: 32px;
  }
  .header__claim {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .footer {
    flex-direction: column;
    align-items: start;
  }
  .footer__logo {
    margin-bottom: 20px;
  }
  .range {
    margin-bottom: 16px;
  }
  .range__item {
    font-size: 14px;
    padding: 6px;
  }
  .segment__input {
    padding: 10px 12px 10px 18px;
    font-size: 18px;
  }
  .segment__bid {
    padding: 7px 12px 7px 18px;
    font-size: 18px;
  }
  .segment__title {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .split {
    gap: 12px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "amount"
                          "point";
  }
  .segment {
    gap: 12px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "amount amount"
                          "from to";
  }
  .segment__setter {
    padding: 6px 12px;
    font-size: 16px;
  }
  .header__logo {
    height: 50px;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .header__left {
    display: block;
  }
  .header__right {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }
  .header-wrapper {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .button__size {
    font-size: 14px;
    padding: 12px 8px;
    line-height: 16.667px; /* 92.593% */
    text-transform: uppercase;
  }
  .chance__text {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .chance__amount {
    font-size: 24px;
    margin-bottom: 8px;
  }
  .chance__percent {
    color: rgba(255, 255, 255, 0.30);
    font-size: 30px;
    margin-bottom: 16px;
  }
  .decision {
    gap: 12px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 44px;
    margin-bottom: 12px;
  }
  .decision__left {
    font-size: 18px;
    line-height: 28px; 
  }
  .decision__play {
    font-size: 18px;
    line-height: 28px; 
    height: 44px;
    margin-bottom: 12px;
  }
  .decision__right {
    font-size: 18px;
    line-height: 28px; 
  }
  .info__maxtitle {
    margin-bottom: 14px;
  }
  .info__maxinfo {
    font-size: 25px;
  }
  .info__max {
    padding: 24px;
    border-radius: 12px;
    background: #191919;
  }
  .info {
    width: 350px;
  }
  .info__text {
    height: 53px;
    padding: 4px 16px;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 500;
  }
}